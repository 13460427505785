.auth-button {
    width: 100%;
    margin-top: 10px;
}

.AuthMainDiv {
    margin: 16% auto 0;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.TopText {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 27px;
    color: #333333;
}

.WelcomeText {
    font-size: 18px;
    color: #333333;
}

.SwapAuthText {
    font-style: italic;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;

    color: #676767;
}

.SwapAuthTextLink {
    font-style: italic;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;

    text-decoration-line: underline;
    float: right;
    color: #7f5beb;
    cursor: pointer;
}

.InputForms {
    box-sizing: border-box;

    border: 1px solid #b5b5b5;
    border-radius: 3px;
    padding: 10px;
}

input,
label {
    display: block;
    width: 100%;
}

label {
    padding-top: 6px;
    padding-bottom: 6px;

    font-family: 'araboto', sans-serif;
}
