/*
 * @NOTE: Prepend a `~` to css file paths that are in your node_modules
 *        See https://github.com/webpack-contrib/sass-loader#imports
 */

@tailwind base;
@tailwind components;
@tailwind utilities;

html * {
    font-family: 'Poppins', sans-serif;
}

html,
body,
#root,
#root>#app {
  width   : 100%;
  height  : 100%;
}

#app {
  display              : grid;
  grid-gap             : 0;
  grid-template-rows   : 0fr 3fr;
  grid-template-columns: minmax(min-content, 0.5fr) 3fr;
  grid-template-areas  :
    "sidebar header"
    "sidebar content";
}

#app>header {
  grid-area: header;
}

#app>aside {
  grid-area: sidebar;
}

#app>main {
  grid-area: content;
}

.active {
  color: #7F5BEB !important;
}

.active::before {
  content         : ' ';
  position        : absolute;
  left            : 0;
  width           : .35em;
  height          : 3em;
  border          : 1px solid #7F5BEB;
  background-color: #7F5BEB;
  border-radius   : 14px;
}

.blob-bg, .wave-bg {
  background-repeat: no-repeat;
  background-position: bottom right;
}

.blob-bg {

  background-image: url('./images/blob.svg');
}

.wave-bg {
  background-image: url('./images/wave.svg');
  background-size: cover
}

.thin-icons svg > path {

  stroke: white;
  stroke-width: 0.75px;
}

.blob-header {
  background-image: url('./images/landing/blob.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  padding: 10rem 5rem;
}